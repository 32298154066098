import React, { Component } from 'react';
import CountUp from 'react-countup';
import { Animated } from 'react-animated-css';
import { Carousel, CarouselItem, Row, Col } from 'react-bootstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import VisibilitySensor from 'react-visibility-sensor';
import Facts from '../components/Facts';
import { PHONE_NUMBER, SINGLE_PHONE } from '../components/Config';
import CollapseValues from '../components/CollapseValues';
// import { Animation } from 'react-animate-style';
import ReactPlayer from 'react-player';
import GalleryPart from '../components/GalleryPart';
type Props = {

}

type States = {
    values: { title: string, content: string }[],
    news: {imagePath:string, articlePath: string, articleDesc:string, dateStr:string}[],
    galleries: { name: string, description:string,  url:string, categories: string[]}[],
    carousel: {path:string }[]
}

class Home extends Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = {
            values: [
                {
                    title: 'Keeping Goals on Track',
                    content: 'NUVAMAX ensures each member of this company puts in consistent effort, focus, and also willing to adapt in order to meet our business goals'
                },
                {
                    title: 'Work as a team',
                    content: "NUVAMAX believes that collaboration is key to achieving a common goal and maximizing the potential of the our resources."
                },
                {
                    title: 'Responsibility',
                    content: 'NUVAMAX believes that responsibility is not only a moral obligation but also a strategic imperative for us to ensure sustainable growth and positive impact on our stakeholders'
                },
                {
                    title: 'Customer Comes First',
                    content: 'NUVAMAX strongly believes that putting the customer at the heart of our business is not just a priority, it is our purpose.'
                },
                {
                    title: 'Ethics and Innovation',
                    content: 'NUVAMAX believes that ethics should be at the forefront of innovation in any company to ensure that progress is made responsibly and sustainably, with a focus on creating positive impact in the environment'
                }
            ],
            news: [
                {articleDesc: "WE JUST COMPLETES $17.6 MILLION MEDICAL CLINIC IN MID-MISSOURI", 
            articlePath: "/", imagePath: "images/news/news1.jpg", dateStr: "JULY 20, 2017" },
            {articleDesc: "THANDLER AIRPORT WATER RECLAMATION FACILITY EXPANSION PROJECT NAMED", 
            articlePath: "/", imagePath: "images/news/news2.jpg", dateStr: "JULY 17, 2017" },
            {articleDesc: "SILICON BENCH AND CORNIKE BEGIN CONSTRUCTION SOLAR FACILITIES", 
            articlePath: "/", imagePath: "images/news/news3.jpg", dateStr: "JULY 13, 2017" }
        ],
        galleries:[
            { name: "Capital Teltway Building", description: "", url: "images/projects/project1.jpg", categories: ["government", "healthcare"] },
            { name: "Ghum Touch Hospital", description: "",url: "images/projects/project2.jpg", categories: ["healthcare"] },
            { name: "TNT East Facility", description: "",url: "images/projects/project3.jpg", categories: ["infrastructure", "commercial"] },
            { name: "Narriot Headquarters", description: "",url: "images/projects/project4.jpg", categories: ["education", "infrastructure"] },
            { name: "Kalas Metrorail",description: "", url: "images/projects/project5.jpg", categories: ["government", "healthcare"] },
            { name: "Ancraft Avenue House",description: "", url: "images/projects/project6.jpg", categories: ["government", "healthcare"] }
        ],
        carousel: [
            {path: "images/slider-main/bg1.jpg"},
            {path: "images/slider-main/bg2.jpg"}, 
            {path: "images/slider-main/bg3.jpg"}
        ]
        }
    }
    render() {
        return (
            <>
                <Carousel className="banner-carousel-1 mb-0 h-100">
                  
                  {this.state.carousel.map(c => {
                    return<Carousel.Item>
              
<img src={c.path} className='img-fluid' />

                    </Carousel.Item>;
                  })}
                   
                </Carousel>
                {/* <div className="banner-carousel banner-carousel-1 mb-0">
                    <div className="banner-carousel-item" style={{ "background-image": "url(images/slider-main/bg1.jpg)" }}>
                        <div className="slider-content">
                            <div className="container h-100">
                                <div className="row align-items-center h-100">
                                    <div className="col-md-12 text-center">
                                        <h2 className="slide-title" data-animation-in="slideInLeft">17 Years of excellence in</h2>
                                        <h3 className="slide-sub-title" data-animation-in="slideInRight">Construction Industry</h3>
                                        <p data-animation-in="slideInLeft" data-duration-in="1.2">
                                            <a href="services.html" className="slider btn btn-primary">Our Services</a>
                                            <a href="contact.html" className="slider btn btn-primary border">Contact Now</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="banner-carousel-item" style={{ "background-image": "url(images/slider-main/bg2.jpg)" }}>
                        <div className="slider-content text-left">
                            <div className="container h-100">
                                <div className="row align-items-center h-100">
                                    <div className="col-md-12">
                                        <h2 className="slide-title-box" data-animation-in="slideInDown">World Class Service</h2>
                                        <h3 className="slide-title" data-animation-in="fadeIn">When Service Matters</h3>
                                        <h3 className="slide-sub-title" data-animation-in="slideInLeft">Your Choice is Simple</h3>
                                        <p data-animation-in="slideInRight">
                                            <a href="services.html" className="slider btn btn-primary border">Our Services</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="banner-carousel-item" style={{ "background-image": "url(images/slider-main/bg3.jpg)" }}>
                        <div className="slider-content text-right">
                            <div className="container h-100">
                                <div className="row align-items-center h-100">
                                    <div className="col-md-12">
                                        <h2 className="slide-title" data-animation-in="slideInDown">Meet Our Engineers</h2>
                                        <h3 className="slide-sub-title" data-animation-in="fadeIn">We believe sustainability</h3>
                                        <p className="slider-description lead" data-animation-in="slideInRight">We will deal with your failure that determines how you achieve success.</p>
                                        <div data-animation-in="slideInLeft">
                                            <a href="contact.html" className="slider btn btn-primary" aria-label="contact-with-us">Get Free Quote</a>
                                            <a href="about.html" className="slider btn btn-primary border" aria-label="learn-more-about-us">Learn more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/*<section className="call-to-action-box no-padding">
                    <div className="container">
                        <div className="action-style-box">
                            <div className="row align-items-center">
                                <div className="col-md-8 text-center text-md-left">
                                    <div className="call-to-action-text">
                                        <h3 className="action-title">We understand your needs on construction</h3>
                                    </div>
                                </div>
                                <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div className="call-to-action-btn">
                                        <a className="btn btn-dark" href="/contact">Request Quote</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
                <section id="ts-features" className="ts-features">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="ts-intro">
                                    <h3 className="into-sub-title">Total Recycling Solution for E-Waste</h3>
                                    <p>NUVAMAX BRIGHT TRADING SDN. BHD. (NUVAMAX), specialize in trading, 
                                    distributions, and dismantling of scrap metal, IT equipment, and 
                                    electrical/electronic instruments. According to the individually 
                                    price which means we collect the scrap metals at your property and 
                                    then pay you cash on the spot. We can offer our client a wide range 
                                    of services in order to make the removal of scrap both convenient 
                                    and cost efficient. As a responsible trader NUVAMAX, committed to 
                                    the implement of environmental solution that benefit both our client 
                                    and the wider community. We work with our client to offer total recycling 
                                    solutions that meet their unique requirements. We are aware of and adhere 
                                    to all statue laws and regulations</p>
                                </div>
                              

                                {/*<div className="gap-20"></div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="ts-service-box">
                                            <span className="ts-service-icon">
                                                <i className="fas fa-trophy"></i>
                                            </span>
                                            <div className="ts-service-box-content">
                                                <h3 className="service-box-title">RECYCLING SERVICES</h3>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="ts-service-box">
                                            <span className="ts-service-icon">
                                                <i className="fas fa-sliders-h"></i>
                                            </span>
                                            <div className="ts-service-box-content">
                                                <h3 className="service-box-title">TRANSPORT SERVICES</h3>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    
                                </div>
                               

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="ts-service-box">
                                            <span className="ts-service-icon">
                                                <i className="fas fa-thumbs-up"></i>
                                            </span>
                                            <div className="ts-service-box-content">
                                                <h3 className="service-box-title">INCINERATION NON‐RECYCLE WASTE</h3>
                                            </div>
                                        </div>
                                       
                                    </div>
                                   
                                    <div className="col-md-6">
                                        <div className="ts-service-box">
                                            <span className="ts-service-icon">
                                                <i className="fas fa-users"></i>
                                            </span>
                                            <div className="ts-service-box-content">
                                                <h3 className="service-box-title">Trucking Services</h3>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>*/}
                                
                            </div>
                            

                            <div className="col-lg-6 mt-4 mt-lg-0">
                                <h3 className="into-sub-title">Our Core Values</h3>
                                <p>NUVAMAX BRIGHT TRADING SDN. BHD. (NUVAMAX) strive to abide based on 
                                    the following core values:</p>

                                <div className="accordion accordion-group" id="our-values-accordion">

                                    {this.state.values.map(value => {
                                        return <CollapseValues title={value.title}>
                                            <> {value.content}</>
                                        </CollapseValues>;
                                    })}

                                </div>
                                

                            </div>
                            
                        </div>
                        
                    </div>
                    
                </section>
                

                <Facts />

                <section id="ts-service-area" className="ts-service-area pb-0">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-12">
                                <h2 className="section-title">We Are Specialists In</h2>
                                <h3 className="section-sub-title">What We Do</h3>
                            </div>
                        </div>
                        {/* <!--/ Title row end --> */}

                        <div className="row">
                            <div className="col-lg-4">
                                <div className="ts-service-box d-flex">
                                    <div className="ts-service-box-img">
                                        <img loading="lazy" src="images/icon-image/service-icon1.png" alt="service-icon" />
                                    </div>
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title"><a href="#">Collection and Transportation</a></h3>
                                        <p>The first step is the collection and transportation of e-waste from different sources 
                                            to the recycling facility. This involves collecting obsolete and discarded electronic 
                                            devices from businesses, households, and public places</p>
                                    </div>
                                </div>
                                {/* <!-- Service 1 end --> */}

                                <div className="ts-service-box d-flex">
                                    <div className="ts-service-box-img">
                                        <img loading="lazy" src="images/icon-image/service-icon2.png" alt="service-icon" />
                                    </div>
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title"><a href="#">Sorting and Dismantling</a></h3>
                                        <p> The second step is the sorting and dismantling of the e-waste. The electronic devices 
                                            are sorted by type and dismantled into their basic components. Hazardous materials like 
                                            batteries and mercury-containing lamps are removed and separately processed.</p>
                                    </div>
                                </div>

                            </div>
                            {/* <!-- Col end --> */}

                            <div className="col-lg-4 text-center">
                                <img loading="lazy" className="img-fluid" src="images/services/service-center.jpg" alt="service-avater-image" />
                            </div>
                            {/* <!-- Col end --> */}

                            <div className="col-lg-4 mt-5 mt-lg-0 mb-4 mb-lg-0">
                                <div className="ts-service-box d-flex">
                                    <div className="ts-service-box-img">
                                        <img loading="lazy" src="images/icon-image/service-icon4.png" alt="service-icon" />
                                    </div>
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title"><a href="#">Recycling</a></h3>
                                        <p>The third step is the actual recycling process where the components are processed to 
                                            extract valuable materials like metals, plastics, and glass. These materials are then 
                                            used in the manufacturing of new electronic devices</p>
                                    </div>
                                </div>
                                {/* <!-- Service 4 end --> */}

                                <div className="ts-service-box d-flex">
                                    <div className="ts-service-box-img">
                                        <img loading="lazy" src="images/icon-image/service-icon5.png" alt="service-icon" />
                                    </div>
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title"><a href="#">Local Foundry</a></h3>
                                        <p>The role of a local foundry in e-waste recycling is crucial for responsible disposal 
                                            of electronic devices. E-waste is classified as a hazardous substance, and if not 
                                            collected and recycled properly, it can have adverse environmental impacts</p>
                                    </div>
                                </div>
                                {/* <!-- Service 7 end --> */}
                            </div>
                            {/* <!-- Col end --> */}
                        </div>
                        {/* <!-- Content row end --> */}

                    </div>
                    {/* <!--/ Container end --> */}
                </section>
                {/* <!-- Service end --> */}

                <section id="ts-service-area" className="ts-service-area pb-0">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-12">
                                <h2 className="section-title">Breakdown</h2>
                                <h3 className="section-sub-title">Our Services</h3>
                            </div>
                        </div>
                        {/* <!--/ Title row end --> */}

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="ts-service-box d-flex">
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title"><a href="#">E-Waste Scrap Disposal</a></h3>
                                        <p>This are items that cannot be disposed through regulation means, as improper disposal could lead to various environmental problems and polution. 
                                            E-waste is denoted as schedule waste with the code SW1110, under the Environmental Quality Regulations 2005 which complies to the Environmental Quality Act 1974. 
                                            Items such as used batteries, light bulbs and other IT based hardware that cannot be salvaged will be disposed in the required manner. Nuvamax has been 
                                            given the license by Department of Environment under the Ministry Of Natural Resources, Environment and Climate Change to conduct e-waste scrap disposal.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="ts-service-box d-flex">
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title"><a href="#">E-Waste Scrap Recycling</a></h3>
                                        <p>IT based items that can be salvaged for their useful components or materials will then be considered for recycling, upon undergoing data erasing/destruction process.
                                          For this items, initial process of data erasing will be conducted at the site or the client's premise to assure data security concern is met, before sending to the 
                                          foundry for further destruction of components for 100% outcome of data clearance. Items that does not require destruction will be considered fo re-purposing / 
                                          re-furbishment upon ensuring no data belonging to the client is still present in the device.   
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">    
                                 <div className="col-lg-6 justify-content-center">
                                     <img loading="lazy" className="img-fluid" src="images/services/old_technology.jpg" alt="service-avater-image" />
                                 </div>
                                 <div className="col-lg-6 text-center">
                                    <img loading="lazy" className="img-fluid" src="images/services/circuit.jpg" alt="service-avater-image" />
                                 </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="news" className="news">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-12">
                                <h2 className="section-title">Work of Excellence</h2>
                                <h3 className="section-sub-title">Our Customers</h3>
                            </div>
                        </div>

                       {/*} 
                        <div className="row">
                       
                            { this.state.news.map((news) => {
                                return newsBlock(news.imagePath, news.articlePath, news.articleDesc, news.dateStr);
                            }) }

                        </div>
                            */}

                        
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
	                        <div className="col-md-3">
                                <img loading="lazy" className="img-fluid" src="images/clients/client1.png" alt="service-avater-image" />
                            </div>
                            <div className="col-md-3">
                                <img loading="lazy" className="img-fluid" src="images/clients/client2.png" alt="service-avater-image" />
                            </div>
                            <div className="col-md-3">
                                <img loading="lazy" className="img-fluid" src="images/clients/client3.png" alt="service-avater-image" />
                            </div>
                            <div className="col-md-3">
                                <img loading="lazy" className="img-fluid" src="images/clients/client4.png" alt="service-avater-image" />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <img loading="lazy" className="img-fluid" src="images/clients/client5.png" alt="service-avater-image" />
                            </div>
                            <div className="col-md-3">
                                <img loading="lazy" className="img-fluid" src="images/clients/client6.png" alt="service-avater-image" />
                            </div>
                            <div className="col-md-3">
                                <img loading="lazy" className="img-fluid" src="images/clients/client7.png" alt="service-avater-image" />
                            </div>
                            <div className="col-md-3">
                                {<img loading="lazy" className="img-fluid" src="images/clients/client8.png" alt="service-avater-image" />}
                            </div>
                        </div>
                        {<div className="row justify-content-center">
                            <div className="col-md-3">
                                <img loading="lazy" className="img-fluid" src="images/clients/client9.png" alt="service-avater-image" />
                            </div>
                        </div>}
                    </div>
                </section>

            </>
        );

        function newsBlock(imagePath:string, articlePath: string, articleDesc:string, dateStr:string) {
            return <div className="col-lg-4 col-md-6 mb-4">
                <div className="latest-post">
                    <div className="latest-post-media">
                        <a href="#" className="latest-post-img">
                            <img loading="lazy" className="img-fluid" src={imagePath} alt="img" />
                        </a>
                    </div>
                    <div className="post-body">
                        <h4 className="post-title">
                            <a href="news-single.html" className="d-inline-block">{ articleDesc }</a>
                        </h4>
                        <div className="latest-post-meta">
                            <span className="post-item-date">
                                <i className="fa fa-clock-o"></i> { dateStr}
                            </span>
                        </div>
                    </div>
                </div>
                {/* <!-- Latest post end --> */}
            </div>;
        }
    }
}

export default Home;