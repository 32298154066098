import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Facts from '../components/Facts';
import { Modal } from 'react-bootstrap';


type Props = {};
type States = {
  path: string,
  show: boolean
};
export default class About extends Component<Props,States> {

constructor(props:Props){
  super(props);

  this.state = {
    path: "",
    show: false
  }
}

  render() {
    return (
      <div>  
        {/*<div id="banner-area" className="banner-area" style={{ "backgroundImage": "url(images/banner/banner1.jpg)" }}>
          <div className="banner-text">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner-heading">
                    <h1 className="banner-title">Meet the Team</h1>
                  </div>
                </div> 
              </div> 
            </div> 
          </div> 
        </div> */}

       {/*} <section id="main-container" className="main-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h3 className="column-title">Who We Are</h3>
                <p></p>
               
              </div> 

              <div className="col-lg-6 mt-5 mt-lg-0">

                <Carousel showArrows={true} autoPlay={true} interval={5000} >
                  <div>
                    <div className="item" style={{ backgroundImage: "url(images/slider-pages/slide-page1.jpg)", height:"300px" }}>
                      <div className="container">
                        <div className="box-slider-content">
                          <div className="box-slider-text">
                            <h2 className="box-slide-title">Leadership</h2>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>

                  <div>
                    <div className="item" style={{ backgroundImage: "url(images/slider-pages/slide-page2.jpg)", height:"300px" }}>
                      <div className="container">
                        <div className="box-slider-content">
                          <div className="box-slider-text">
                            <h2 className="box-slide-title">Relationships</h2>
                          </div>
                        </div>
                      </div>
                    </div> 

                  </div>

                  <div>
                    <div className="item" style={{ backgroundImage: "url(images/slider-pages/slide-page3.jpg)", height:"300px" }}>
                      <div className="container">
                        <div className="box-slider-content">
                          <div className="box-slider-text">
                            <h2 className="box-slide-title">Performance</h2>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>
                </Carousel>


                

              </div>
            </div>

          </div> 
        </section>*/}
                <section id="ts-service-area" className="ts-service-area pb-0">
                  <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                           <h2 className="section-title">OUR PLEDGE</h2>
                           <h3 className="section-sub-title">Our Mission & Vision</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ts-service-box d-flex">
                                <div className="ts-service-box-info">
                                        <h3 className="service-box-title text-center"><a href="#">Mission</a></h3>
                                        <p>To accomplish NUVAMAX as a promoting solid waste resource, sustainable environment, cost effective practices through an integrated system of waste reduction and innovative technology</p>
                                        <h3 className="service-box-title text-center"><a href="#">Vision</a></h3>
                                        <p>To reduce, reuse and recover the amount of waste by endorse individual and corporate accountability. Our vision to transform our business to utilize waste as a resource to eliminate the need for landfills</p>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </section>

        <section id="ts-team" className="ts-team">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <h2 className="section-title">Our Credentials</h2>
                <h3 className="section-sub-title">License & Certification</h3>
              </div>
            </div> 

            <div className="row">
                  <div className="ts-service-box d-flex">
                      <div className="ts-service-box-info">
                          <h3 className="service-box-title"><a href="#">Suruhanjaya Syarikat Malaysia (SSM)</a></h3>
                          <p>SSM Registration number 202203314839 (NS0277864-X)</p>
                      </div>
                      <div className="ts-service-box-info">
                          <h3 className="service-box-title"><a href="#">Registered Client Under Cenviro Recycling</a></h3>
                          <p>Collection of Schedule Waste SW 417 for recyling/recovery</p>
                     </div>
              </div>
              <div className="row">
                 <div className="col-lg-4">
                     <img onClick={() => {
                      this.setState({
                        path: "images/team/SSM Cert 2024.png",
                        show: true
                      });
                     }} loading="lazy" className="img-fluid pop-img" src="images/team/SSM Cert 2024.png" alt="service-avater-image" />
                  </div>
                 <div className="col-lg-4">
                     <img loading="lazy" onClick={() => {
                      this.setState({
                        path: "images/team/Cenviro.png",
                        show: true
                      });
                     }} className="img-fluid pop-img" src="images/team/Cenviro.png" alt="service-avater-image" />
                  </div>
               </div>

         
              </div>
          </div>
        </section>
        <section id="ts-team" className="ts-team">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <h2 className="section-title">Quality Service</h2>
                <h3 className="section-sub-title">Professional Team</h3>
              </div>
            </div> 

            <div className="row">
              <div className="col-lg-4">
                  <div className="ts-service-box d-flex">
                      <div className="ts-service-box-info">
                          <h3 className="service-box-title"><a href="#">Uma Devi Nadarajan</a></h3>
                          <p>Director</p>
                          <p>Oversee the entire operations of the business with relevant personnel. 
                            Directly involved in the company's finance and business initiatives</p>
                      </div>
                  </div>
                  {/*<div className="ts-service-box d-flex">
                      <div className="ts-service-box-info">
                          <h3 className="service-box-title"><a href="#">Uma Nadarajan</a></h3>
                          <p>Director of Finance & Business Support</p>
                      </div>
                  </div>
                  <div className="ts-service-box d-flex">
                      <div className="ts-service-box-info">
                          <h3 className="service-box-title"><a href="#">Munes Venugopal</a></h3>
                          <p>Associate Director, Transport & Operations</p>
                      </div>
                  </div>*/}
              </div>
              <div className="col-lg-8">
                <img loading="lazy" className="img-fluid" src="images/team/team4.jpg" alt="service-avater-image" />
              </div>
            </div>
          </div>

            {/*<div className="row">
              <div className="col-lg-12">
                <div id="team-slide" className="team-slide row">
                  <div className="item col-lg-4">
                    <div className="ts-team-wrapper">
                      <div className="team-img-wrapper">
                        <img loading="lazy" className="w-100" src="images/team/team1.jpeg" alt="team-img" />
                      </div>
                      <div className="ts-team-content">
                        <h3 className="ts-name">Manimaran Manikkan</h3>
                        <p className="ts-designation">Chief Executive Officer</p>
                        <div className="team-social-icons">
                          <a target="_blank" href="#"><i className="fab fa-facebook-f"></i></a>
                          <a target="_blank" href="#"><i className="fab fa-twitter"></i></a>
                          <a target="_blank" href="#"><i className="fab fa-google-plus"></i></a>
                          <a target="_blank" href="#"><i className="fab fa-linkedin"></i></a>
                        </div>
                      </div>
                    </div> 
                  </div> 

                  <div className="item col-lg-4">
                    <div className="ts-team-wrapper">
                      <div className="team-img-wrapper">
                        <img loading="lazy" className="w-100" src="images/team/team2.jpeg" alt="team-img" />
                      </div>
                      <div className="ts-team-content">
                        <h3 className="ts-name">Uma Nadarajan</h3>
                        <p className="ts-designation">Director</p>
                        <div className="team-social-icons">
                          <a target="_blank" href="#"><i className="fab fa-facebook-f"></i></a>
                          <a target="_blank" href="#"><i className="fab fa-twitter"></i></a>
                          <a target="_blank" href="#"><i className="fab fa-linkedin"></i></a>
                        </div> 
                      </div>
                    </div> 
                  </div> 

                  <div className="item col-lg-4">
                    <div className="ts-team-wrapper">
                      <div className="team-img-wrapper">
                        <img loading="lazy" className="w-100" src="images/team/team3.jpeg" alt="team-img" />
                      </div>
                      <div className="ts-team-content">
                        <h3 className="ts-name">Munes Venugopal</h3>
                        <p className="ts-designation">Director</p>
                        <div className="team-social-icons">
                          <a target="_blank" href="#"><i className="fab fa-twitter"></i></a>
                          <a target="_blank" href="#"><i className="fab fa-google-plus"></i></a>
                          <a target="_blank" href="#"><i className="fab fa-linkedin"></i></a>
                        </div> 
                      </div>
                    </div> 
                  </div> 

                  <div className="item col-lg-3 col-xs-3 col-sm-6">
                    <div className="ts-team-wrapper">
                      <div className="team-img-wrapper">
                        <img loading="lazy" className="w-100" src="images/team/team4.jpg" alt="team-img" />
                      </div>
                      <div className="ts-team-content">
                        <h3 className="ts-name">Ayesha Stewart</h3>
                        <p className="ts-designation">Finance Officer</p>
                        <div className="team-social-icons">
                          <a target="_blank" href="#"><i className="fab fa-facebook-f"></i></a>
                          <a target="_blank" href="#"><i className="fab fa-twitter"></i></a>
                          <a target="_blank" href="#"><i className="fab fa-google-plus"></i></a>
                          <a target="_blank" href="#"><i className="fab fa-linkedin"></i></a>
                        </div> 
                      </div>
                    </div> 
                  </div> 
                </div> 
              </div>
            </div> */}
        </section> 
        
        <Modal size='xl' show={this.state.show} onHide={() => {
          this.setState({show: false});
        }}>
          <Modal.Body>
            <img className='img-fluid pop-img' src={this.state.path} alt="" />
          </Modal.Body>
        </Modal>
        
        </div>
    );
  }
}
